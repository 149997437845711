import { Button, Modal } from "flowbite-react";

export default function Privacy({ openModal, closeModal }) {
  return (
    <Modal show={openModal} onClose={() => closeModal(false)}>
      <Modal.Header>Cookies Policy</Modal.Header>
      <Modal.Body>
        <div className="container mx-auto p-4">
          <p className="mb-4">
            <strong>Introduction:</strong> Our website currently does not use
            cookies. However, this Cookies Policy outlines what cookies are and
            how we would use them if we start using them in the future.
          </p>

          <h2 className="text-2xl font-semibold mb-2">What Are Cookies?</h2>
          <p className="mb-4">
            Cookies are small text files that are stored on your device when you
            visit a website. They are used to remember your preferences and
            improve your browsing experience.
          </p>

          <h2 className="text-2xl font-semibold mb-2">
            Types of Cookies We May Use
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Essential Cookies:</strong> These are necessary for the
              website to function properly.
            </li>
            <li>
              <strong>Performance Cookies:</strong> These cookies help us
              understand how visitors interact with our website by collecting
              and reporting information anonymously.
            </li>
            <li>
              <strong>Functional Cookies:</strong> These cookies enable the
              website to provide enhanced functionality and personalization.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-2">How We Use Cookies</h2>
          <p className="mb-4">
            Currently, we do not use cookies on our website. If this changes, we
            will update this policy and inform you accordingly.
          </p>

          <h2 className="text-2xl font-semibold mb-2">Managing Cookies</h2>
          <p className="mb-4">
            Most web browsers allow you to control cookies through their
            settings preferences. However, if you disable cookies, you may not
            be able to use some features of our website.
          </p>

          <h2 className="text-2xl font-semibold mb-2">
            Changes to This Policy
          </h2>
          <p className="mb-4">
            We may update this Cookies Policy from time to time. We will notify
            you of any changes by posting the new policy on this page.
          </p>

          <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
          <p className="mb-4">
            If you have any questions about this Cookies Policy, please contact
            us at info@gameresolution.app.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModal(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
