import {
  collection,
  query,
  where,
  getDocs,
  runTransaction,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/config";

const USERS_COLLECTION = collection(db, "users");

export const updateUserProfile = async (userId, profileData) => {
  const { userName, email, fullName, street, city, state, zipCode } =
    profileData;
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("userName", "==", userName));

  await runTransaction(db, async (transaction) => {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty && querySnapshot.docs[0].id !== userId) {
      throw new Error("The userName is already in use.");
    }

    const userRef = doc(db, "users", userId);
    await transaction.set(
      userRef,
      {
        userName,
        email,
        fullName,
        address: {
          street,
          city,
          state,
          zipCode,
        },
      },
      { merge: true }
    );
  });
};

export const fetchUserData = async (userId) => {
  const userRef = doc(db, "users", userId);
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) {
    const data = userDoc.data();
    return { ...data };
  }
};

export const filterUSers = async (prefix) => {
  try {
    if (prefix.length >= 4) {
      // Realiza la consulta
      const q = query(
        USERS_COLLECTION,
        where("userName", ">=", prefix),
        where("userName", "<", prefix + "\uf8ff")
      );

      // Realizar la consulta
      const querySnapshot = await getDocs(q);

      // Procesar los resultados
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });

      // Retornar los usuarios encontrados
      return users;
    }
    return [];
  } catch (error) {
    console.error("Error al buscar usuarios:", error);
    return [];
  }
};

export const saveUser = async (userId, data) => {
  const documentRef = doc(db, "users", userId);
  await setDoc(documentRef, data);
};

export const sendFriendRequest = async (MyId, friendId, friendData) => {
  const friendDocRef = doc(collection(db, `users/${friendId}/friends`), MyId);
  try {
    await setDoc(friendDocRef, friendData);
    console.log("Document written with custom ID: ", friendData);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getFriends = async (userID) => {
  const friendCollection = collection(db, `users/${userID}/friends`);

  try {
    const querySnapshot = await getDocs(friendCollection);
    const unacceptedFriendsPromises = querySnapshot.docs.map(async (doc) => {
      const result = await fetchUserData(doc.id);
      return {
        userName: result.userName,
        id: result.id,
        color: result.color,
        ...doc.data(),
      };
    });

    const unacceptedFriends = await Promise.all(unacceptedFriendsPromises);
    return unacceptedFriends;
  } catch (error) {
    console.error("Error getting documents: ", error);
    return [];
  }
};

export const AcceptFriend = async (friendId, userId) => {
  const friendDocRef = doc(db, `users/${userId}/friends/${friendId}`);
  try {
    await updateDoc(friendDocRef, {
      accepted: true,
    });
    const updatedDoc = await getDoc(friendDocRef);
    if (updatedDoc.exists()) {
      const friendData = updatedDoc.data();
      const friendDocRef = doc(
        collection(db, `users/${friendId}/friends`),
        userId
      );
      try {
        await setDoc(friendDocRef, {
          accepted: true,
        });
        console.log("Document written with custom ID: ", friendData);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      return {
        id: updatedDoc.id,
        ...friendData,
      };
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};
export const fetchGamesDetails = async (gameIds) => {
  if (gameIds.length === 0) {
    return [];
  }

  const gamesQuery = query(
    collection(db, "games"),
    where("__name__", "in", gameIds)
  );
  const querySnapshot = await getDocs(gamesQuery);
  const gamesDetails = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return gamesDetails;
};

export const fetchUserGames = async (userId) => {
  const querySnapshot = await getDocs(collection(db, `users/${userId}/games`));
  const games = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    completed: doc.data().completed,
    review: doc.data().review,
    status: doc.data().status,
  }));
  return games;
};

export const fetchUserGamesAndDetails = async (userId) => {
  const userGames = await fetchUserGames(userId);
  const gameIds = userGames.map((game) => game.id);

  if (gameIds.length > 0) {
    const gamesDetails = await fetchGamesDetails(gameIds);

    // Combina los datos de userGames con los detalles de los juegos
    const combinedGames = userGames.map((userGame) => ({
      ...userGame,
      ...gamesDetails.find((gameDetail) => gameDetail.id === userGame.id),
    }));

    return combinedGames;
  }
};

export const getGamesFromUser = async (userId) => {
  const games = await getDocumentsGroupedByYear(userId);
  const gameDocs = await Promise.all(
    Object.keys(games).reduce((acc, year) => {
      const yearDocs = games[year].map(async (game) => {
        const gameDocRef = doc(db, "games", game.id);
        const gameDoc = await getDoc(gameDocRef);
        if (gameDoc.exists()) {
          return {
            year: year,
            id: game.id,
            detail: game,
            title: gameDoc.data(),
          };
        } else {
          console.log(`No such game document with ID: ${game.id}`);
          return null;
        }
      });
      return acc.concat(yearDocs);
    }, [])
  );
  const groupedByYear = gameDocs.reduce((acc, doc) => {
    if (doc) {
      // Asegurarse de que el documento no sea null
      const year = doc.year;
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(doc);
    }
    return acc;
  }, {});
  return groupedByYear;
};

export const getGamesByYears = async (userId) => {
  try {
    const games = await getDocumentsGroupedByYear(userId);

    const gameDocs = await Promise.all(
      Object.keys(games).reduce((acc, year) => {
        const yearDocs = games[year].map(async (game) => {
          const gameDocRef = doc(db, "games", game.id);
          const gameDoc = await getDoc(gameDocRef);
          if (gameDoc.exists()) {
            return {
              year: year,
              id: game.id,
              detail: game,
              title: gameDoc.data(),
            };
          } else {
            console.log(`No such game document with ID: ${game.id}`);
            return null;
          }
        });
        return acc.concat(yearDocs);
      }, [])
    );

    const groupedByYear = gameDocs.reduce((acc, doc) => {
      if (doc) {
        // Asegurarse de que el documento no sea null
        const year = doc.year;
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(doc);
      }
      return acc;
    }, {});
    return groupedByYear;
  } catch (error) {
    console.error("Error getting documents: ", error);
  }
};

const getDocumentsGroupedByYear = async (userId) => {
  const documents = [];
  try {
    const querySnapshot = await getDocs(
      collection(db, `users/${userId}/games`)
    );
    querySnapshot.forEach((doc) => {
      documents.push({ id: doc.id, ...doc.data() });
    });

    // Agrupar documentos por año
    const groupedByYear = documents.reduce((groups, doc) => {
      const year = doc.year; // Asumiendo que tienes un campo releaseDate
      if (!groups[year]) {
        groups[year] = [];
      }
      groups[year].push(doc);
      return groups;
    }, {});

    return groupedByYear;
  } catch (e) {
    console.error("Error obteniendo documentos: ", e);
  }
};

export const isUserNameTaken = async (userName) => {
  console.log(userName);
  if (userName === undefined) {
    return true;
  }
  const usersRef = USERS_COLLECTION;
  const q = query(usersRef, where("userName", "==", userName));
  try {
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return true;
    }

    return false;
  } catch (error) {
    return true;
  }
};
