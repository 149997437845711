import { useState, useEffect } from "react";
import { db } from "../firebase/config";
import {
  collection,
  setDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { selectUsers } from "../store/usersSlice";
import Games from "../components/Games";
import Search from "../components/Search";
import { deleteGame } from "../services/gameService";

const saveGameToCollection = async (userId, gameId, data) => {
  try {
    // Define un ID específico para el documento
    const docRef = doc(db, `users/${userId}/games`, gameId);

    // Agrega el documento con el ID personalizado
    await setDoc(docRef, data);

    console.log(`Documento escrito con ID: ${gameId}`);
  } catch (e) {
    console.error("Error configurando documento: ", e);
  }
};

const getDocumentsGroupedByYear = async (userId) => {
  const yearMap = {};
  try {
    const querySnapshot = await getDocs(
      collection(db, `users/${userId}/games`)
    );
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const year = data.year;

      // Si el año no existe en el mapa, inicialízalo con un objeto que contenga el año y un array de juegos
      if (!yearMap[year]) {
        yearMap[year] = { year: year, games: [] };
      }

      // Agrega el juego al array correspondiente al año
      yearMap[year].games.push({ id: doc.id, ...data });
    });

    return yearMap;
  } catch (e) {
    console.error("Error obteniendo documentos: ", e);
  }
};
export default function GamesContainer() {
  const user = useSelector(selectUsers);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [games, setGames] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [yearSelected, setYearSelected] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchGames = async () => {
      const documents = [];
      try {
        const games = await getDocumentsGroupedByYear(user.currentUser.id);
        for (const year in games) {
          const yearData = games[year];
          const detailedGames = await Promise.all(
            yearData.games.map(async (game) => {
              const gameDocRef = doc(db, "games", game.id);
              const gameDoc = await getDoc(gameDocRef);
              return { ...game, ...gameDoc.data() };
            })
          );
          yearData.games = detailedGames;
          documents.push(yearData);
        }
        setGames(documents);
      } catch (error) {
        console.error("Error getting documents: ", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchGames();
  }, [user.currentUser.id]);

  useEffect(() => {
    // Define la función de búsqueda
    const performSearch = async () => {
      try {
        if (searchTerm.length >= 4) {
          const games = await fetch(
            `https://api.gameresolution.app/api/v1/games/${searchTerm}`
          );
          const data = await games.json();
          setSearchResults(data.games);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error("Error en la solicitud de búsqueda:", error);
      }
    };

    // Configura el debounce con un retraso de 300ms
    const debounceTimeout = setTimeout(() => {
      performSearch();
    }, 300);

    // Limpia el temporizador cuando el componente se desmonte o cambie el término de búsqueda
    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);

  const handleSaveItem = async (game) => {
    const detail = {
      id: game.id,
      year: yearSelected,
      review: "",
      status: 0,
      completed: false,
    };

    try {
      saveGameToCollection(user.currentUser.id, game.id, detail);
      const docRef = await doc(collection(db, "games"), game.id);
      await setDoc(docRef, {
        id: game.id,
        name: game.name,
        description: game.description,
        platforms: game.platforms,
        imageUrl: game.imageUrl,
        gameplayMain: game.gameplayMain,
        gameplayMainExtra: game.gameplayMainExtra,
        gameplayCompletionist: game.gameplayCompletionist,
        similarity: game.similarity,
        playableOn: game.playableOn,
      });

      const documentRef = doc(
        db,
        `users/${user.currentUser.id}/${yearSelected}`,
        game.id
      );

      try {
        await setDoc(documentRef, {
          review: "",
          status: true,
          completed: false,
        });
      } catch (error) {
        console.error("Error al agregar el documento:", error);
      }

      setGames((prevGamesData) => {
        const yearIndex = prevGamesData.findIndex(
          (yearGroup) => yearGroup.year === yearSelected
        );
        if (yearIndex !== -1) {
          // Si el año ya existe, agrega el nuevo juego a la lista de juegos de ese año
          const updatedYearGroup = {
            ...prevGamesData[yearIndex],
            games: [{ ...game, status: 0 }, ...prevGamesData[yearIndex].games],
          };
          return [
            ...prevGamesData.slice(0, yearIndex),
            updatedYearGroup,
            ...prevGamesData.slice(yearIndex + 1),
          ];
        } else {
          // Si el año no existe, crea una nueva entrada para ese año con el nuevo juego
          const newYearGroup = {
            year: yearSelected,
            games: [{ ...game, status: 0 }],
          };
          return [newYearGroup, ...prevGamesData];
        }
      });

      setSearchResults([]);
      setSearchTerm("");
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  const handleSerchTerm = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteGame = async (gameId) => {
    await deleteGame(user.currentUser.id, gameId)
      .then(() => {
        setGames((prevGamesData) =>
          prevGamesData.map((yearGroup) => ({
            ...yearGroup,
            games: yearGroup.games.filter((game) => game.id !== gameId),
          }))
        );
      })
      .catch((error) => {
        console.error("Error al eliminar el elemento:", error);
      });
  };

  const handleCompletedGame = async (gameId, status, year) => {
    try {
      const docRef = await doc(
        db,
        "users",
        user.currentUser.id + "/games/" + gameId
      );
      const complete = !status;
      await updateDoc(docRef, {
        completed: complete,
      });
      setGames((prevState) => {
        // Copiar el estado anterior
        const newState = { ...prevState };
        // Crear una copia del array del año específico
        newState[year] = newState[year].map((game) => {
          if (game.detail.id === gameId) {
            // Crear una copia del juego específico y actualizar el campo "completed"
            return {
              ...game,
              detail: {
                ...game.detail,
                completed: !game.detail.completed,
              },
            };
          }
          return game;
        });
        return newState;
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };
  const handleYear = (event) => {
    setYearSelected(event.target.value);
  };
  return (
    <div>
      <Search
        results={searchResults}
        saveItem={handleSaveItem}
        searching={handleSerchTerm}
        term={searchTerm}
        year={handleYear}
      />
      <div className="flex flex-col items-center justify-center md:flex-row">
        <Games
          games={games}
          loading={loading}
          error={error}
          deleteGame={handleDeleteGame}
          doneGame={handleCompletedGame}
        />
      </div>
    </div>
  );
}
