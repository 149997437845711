function AvatarWithLetter({ name, bgColor }) {
  const newStyle = `relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full dark:bg-gray-800 bg-${bgColor}-900`;
  const firstLetter = (name) => {
    return Array.from(name)[0];
  };

  return (
    <div className={newStyle}>
      <span className="font-medium text-white dark:text-gray-300 uppercase">
        {firstLetter(name)}
      </span>
    </div>
  );
}

export default AvatarWithLetter;
