import { useState } from "react";
import { Button, Card, Checkbox, Label, TextInput } from "flowbite-react";
import { auth } from "../firebase/config";
import { Modal } from "flowbite-react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  saveUser,
  fetchUserData,
  isUserNameTaken,
} from "../services/userService";
import { colors } from "../services/colors";
import ForgotPassword from "../components/ForgotPassword";

export default function Login({ isModalOpen, closeModal }) {
  const [userCredentials, setUserCredentials] = useState({});
  const [loginType, setLoginType] = useState("login");
  const [error, setError] = useState("");
  const [forgotModal, setForgotModal] = useState(false);

  const handleSignup = async (e) => {
    setError("");
    e.preventDefault();
    try {
      const isUserTaken = await isUserNameTaken(userCredentials.userName);
      console.log(isUserTaken);
      if (isUserTaken) {
        setError(error.message || "The username is already in use.");
        return;
      }
    } catch (error) {
      setError(error.message || "The username is already in use.");
      return;
    }

    createUserWithEmailAndPassword(
      auth,
      userCredentials.email,
      userCredentials.password
    )
      .then((result) => {
        console.log(result.user);

        const initialData = {
          userName: userCredentials.userName,
          id: result.user.uid,
          color: colors[Math.floor(Math.random() * colors.length)],
        };

        saveUser(result.user.uid, initialData);
      })
      .catch((error) => {
        setError(error.message || "Please verify your email and password");
        // ..
      });
  };

  function handleLogin(e) {
    e.preventDefault();
    setError("");
    signInWithEmailAndPassword(
      auth,
      userCredentials.email,
      userCredentials.password
    ).catch((error) => {
      setError(error.message);
    });
  }

  function handleLoginByGoogle(e) {
    e.preventDefault();
    setError("");
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        fetchUserData(user.uid).catch(() => {
          const initialData = {
            id: user.uid,
            color: colors[Math.floor(Math.random() * colors.length)],
          };
          saveUser(user.uid, initialData);
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  function handleCredentials(e) {
    setUserCredentials({ ...userCredentials, [e.target.name]: e.target.value });
  }

  function handlePasswordReset() {
    setForgotModal(true);
    /*const email = prompt("please enter your email");
    sendPasswordResetEmail(auth, email);
    alert("email sent! check your email for password reset");*/
  }
  function forgotCloseModal() {
    setForgotModal(false);
  }

  return (
    <>
      <ForgotPassword isModalOpen={forgotModal} closeModal={forgotCloseModal} />
      <Modal show={isModalOpen} size="md" onClose={closeModal} popup>
        <Modal.Header />
        <Modal.Body>
          <Card className="max-w-sm">
            <form className="flex flex-col gap-4">
              <Button.Group className="flex items-center justify-center">
                <Button
                  color={loginType == "login" ? "info" : "gray"}
                  onClick={() => setLoginType("login")}
                >
                  Login
                </Button>
                <Button
                  color={loginType == "signup" ? "info" : "gray"}
                  onClick={() => setLoginType("signup")}
                >
                  Signup
                </Button>
              </Button.Group>
              {loginType === "signup" && (
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="userName" value="User name" />
                  </div>
                  <TextInput
                    id="userName"
                    type="email"
                    placeholder="nickname"
                    name="userName"
                    onChange={(e) => {
                      handleCredentials(e);
                    }}
                    required
                  />
                </div>
              )}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Your email" />
                </div>
                <TextInput
                  id="email1"
                  type="email"
                  placeholder="name@flowbite.com"
                  name="email"
                  onChange={(e) => {
                    handleCredentials(e);
                  }}
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="password1" value="Your password" />
                </div>
                <TextInput
                  id="password1"
                  type="password"
                  name="password"
                  onChange={(e) => {
                    handleCredentials(e);
                  }}
                  required
                />
              </div>
              {loginType === "login" && (
                <div className="flex items-center gap-2">
                  <Checkbox id="remember" />
                  <Label htmlFor="remember">Remember me</Label>
                </div>
              )}
              {loginType === "login" && (
                <>
                  <Button onClick={(e) => handleLogin(e)}>Login</Button>
                  <p onClick={handlePasswordReset}>Forgot Password?</p>
                </>
              )}

              {loginType === "signup" && (
                <Button onClick={(e) => handleSignup(e)}>Register</Button>
              )}

              {error && (
                <div className=" text-sm duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 text-red-600 dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
                  {error}
                </div>
              )}
              <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300 dark:before:border-neutral-500 dark:after:border-neutral-500">
                <p className="mx-4 mb-0 text-center font-semibold dark:text-neutral-200">
                  OR
                </p>
              </div>
              <div className=" flex items-center justify-center rounded bg-primary px-7  pt-3 text-center text-sm font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong">
                <button
                  type="button"
                  className="text-white w-full  bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between mr-2 mb-2"
                  onClick={(e) => handleLoginByGoogle(e)}
                >
                  <svg
                    className="mr-2 -ml-1 w-4 h-4"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="google"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 488 512"
                  >
                    <path
                      fill="currentColor"
                      d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                    ></path>
                  </svg>
                  Sign up with Google<div></div>
                </button>
              </div>
              <div className="mb-3 flex items-center justify-center rounded bg-primary px-7 pb-2.5  text-center text-sm font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"></div>
            </form>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}
