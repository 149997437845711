import { useState } from "react";
import { updateGameStatus, GAME_STATES } from "../services/gameService";
import { Button } from "flowbite-react";
import { useSelector } from "react-redux";
import { selectUsers } from "../store/usersSlice";
import { LuRefreshCcw } from "react-icons/lu";

const GameStateButton = ({ game, onUpdateStatus }) => {
  const states = GAME_STATES;
  const [currentState, setCurrentState] = useState(game.status); // Estado inicial
  const user = useSelector(selectUsers);

  const stateClasses = {
    Pending: "gray",
    Playing: "blue",
    Completed: "success",
  };

  const handleClick = () => {
    const newState = (currentState + 1) % states.length;
    setCurrentState(newState);
    updateGameStatus(user.currentUser.id, game.id, newState);
    onUpdateStatus(newState);
  };
  const restartStatus = () => {
    setCurrentState(0);
    updateGameStatus(user.currentUser.id, game.id, 0);
    onUpdateStatus(0);
  };

  return (
    <div className="mr-2">
      {currentState === 2 ? (
        <Button color="success" size="xs" onClick={restartStatus}>
          <span className="flex items-center justify-between ">
            <LuRefreshCcw className="mr-2" />
            {states[currentState]}
          </span>
        </Button>
      ) : (
        <Button
          size="xs"
          onClick={handleClick}
          color={stateClasses[states[currentState]]}
        >
          <span className="flex items-center justify-between ">
            <LuRefreshCcw className="mr-2" />
            {states[currentState]}
          </span>
        </Button>
      )}
    </div>
  );
};

export default GameStateButton;
