import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    currentUser: {
      id: null,
      userName: "",
      fullName: "",
      color: "",
      address: {
        street: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
  },
  reducers: {
    setUser: (users, action) => {
      users.currentUser = action.payload;
    },
    updateProfile: (state, action) => {
      const {
        userName,
        fullName,
        street,
        city,
        state: addrState,
        zipCode,
        color,
      } = action.payload;
      state.currentUser.userName = userName;
      state.currentUser.fullName = fullName;
      (state.currentUser.color = color),
        (state.currentUser.address = {
          street,
          city,
          state: addrState,
          zipCode,
        });
    },
  },
});

export const { setUser, updateProfile } = usersSlice.actions;
export const selectUsers = (state) => state.users;

export default usersSlice.reducer;
