import { Button, Navbar, Dropdown } from "flowbite-react";

import { selectUsers, setUser } from "../store/usersSlice";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";
import logo from "../assets/logo.png";
import Friends from "../components/Friends";
import AvatarLetter from "../components/AvatarLetter";
import { LuUsers } from "react-icons/lu";

export default function MainNav({ handleLogin, handleProfile }) {
  const dispatch = useDispatch();
  const user = useSelector(selectUsers);

  function handleSignOut() {
    signOut(auth)
      .then(() => {
        dispatch(setUser(null));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Game Resolution
        </span>
      </Navbar.Brand>
      {user.currentUser ? (
        <>
          <div className="flex md:order-2">
            <Dropdown
              arrowIcon={false}
              inline
              label={<LuUsers size="1.9em" color="gray" className="mr-5" />}
            >
              <div>
                <Friends />
              </div>
            </Dropdown>
            <Dropdown
              className="ml-5"
              arrowIcon={false}
              inline
              label={
                <AvatarLetter
                  name={user.currentUser.userName}
                  bgColor={user.currentUser.color}
                />
              }
            >
              <Dropdown.Header>
                <span className="block text-sm">
                  {user.currentUser.userName}
                </span>
                <span className="block truncate text-sm font-medium">
                  {user.currentUser.email}
                </span>
              </Dropdown.Header>
              <Dropdown.Item
                onClick={() => handleProfile(user.currentUser.email)}
              >
                Settings
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
            </Dropdown>
          </div>
        </>
      ) : (
        <>
          <div className="flex md:order-2">
            <Button onClick={handleLogin}>Get started</Button>
          </div>
        </>
      )}
    </Navbar>
  );
}
