import { useState } from "react";
import { LuSearch } from "react-icons/lu";
import { filterUSers, sendFriendRequest } from "../services/userService";
import { List } from "flowbite-react";
import { selectUsers } from "../store/usersSlice";
import { useSelector } from "react-redux";
import AvatarLetter from "../components/AvatarLetter";
import FriendDetail from "../components/FriendDetail";

export default function Filter() {
  const [dataSearch, setDataSearch] = useState("");
  const [usersFinder, setUsersFinder] = useState([]);
  const [Friend, SetFriend] = useState(null);
  const user = useSelector(selectUsers);

  const handleInputChange = (event) => {
    setDataSearch(event.target.value);
    if (event.target.value.length >= 4) {
      filterUSers(dataSearch).then((result) => {
        setUsersFinder(result);
      });
    }
  };

  const handleInputKeyDown = (event) => {
    event.stopPropagation();
  };
  const showFriendDetail = (user) => {
    SetFriend(user);
  };

  const handleCloseDetail = (friend) => {
    const data = {
      accepted: false,
    };
    sendFriendRequest(user.currentUser.id, friend.id, data).then(() => {
      SetFriend(null);
    });
  };

  return (
    <div className="relative w-full max-w-xs">
      <input
        type="text"
        value={dataSearch}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm z-30"
        placeholder="Search..."
      />
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <LuSearch className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>

      <List
        unstyled
        className=" max-w-md divide-y divide-gray-200 dark:divide-gray-700"
      >
        {usersFinder.map((user) => {
          return (
            <List.Item
              key={user.userName}
              className="pb-3 sm:pb-4 bg-white absolute w-full max-h-40 overflow-auto z-40 cursor-pointer"
            >
              <div className="flex items-center space-x-4 rtl:space-x-reverse mt-1  ">
                <AvatarLetter name={user.userName} bgColor={user.color} />
                <div className="min-w-0 flex-1">
                  <p
                    className="truncate text-sm font-medium text-gray-900 dark:text-white"
                    onClick={() => showFriendDetail(user)}
                  >
                    {user.userName}
                  </p>
                </div>
              </div>
            </List.Item>
          );
        })}
      </List>
      {Friend && (
        <FriendDetail
          className=" z-50"
          user={Friend}
          closeModal={handleCloseDetail}
        />
      )}
    </div>
  );
}
