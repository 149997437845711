import { useState } from "react";
import { Modal, Button, Label, TextInput } from "flowbite-react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase/config";
export default function ForgotPassword({ isModalOpen, closeModal }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    message: "",
    color: "",
    disabled: false,
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  function handlePasswordReset(event) {
    event.preventDefault();
    if (validateEmail(email)) {
      setError({ message: "", color: "", disabled: false });
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setError({
            message:
              "An email with password reset instructions has been sent to your address.",
            color: "success",
            disabled: true,
          });
        })
        .catch((error) => {
          setError({
            code: error,
            message: "Failed to send email. Please try again.",
            color: "failure",
            disabled: false,
          });
        });
    } else {
      setError({
        message: "Please enter a valid email address.",
        color: "failure",
        disabled: false,
      });
    }
  }
  function handleEmail(event) {
    setEmail(event.target.value);
  }
  return (
    <Modal show={isModalOpen} size="md" onClose={closeModal} popup>
      <Modal.Header />
      <Modal.Body>
        <form className="flex max-w-md flex-col gap-4">
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="email1"
                value="No worries! Enter your email address below, and we'll send you instructions on how to reset your password."
              />
            </div>
            <TextInput
              id="email1"
              type="email"
              placeholder="name@flowbite.com"
              value={email}
              onChange={(event) => handleEmail(event)}
              required
              color={error.color}
              helperText={<>{error.message}</>}
            />
          </div>

          <Button
            type="submit"
            onClick={(event) => handlePasswordReset(event)}
            disabled={error.disabled}
          >
            Send
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
