import { Modal } from "flowbite-react";

export default function FriendGameDetail({
  openModal,
  setOpenModal,
  gameDetail,
}) {
  return (
    <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
      <Modal.Header />
      <Modal.Body>
        <div className="space-y-6">
          <h3 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {gameDetail.title.name}
          </h3>
          <p> {gameDetail.detail.review}</p>
          <p className="text-2xl">
            score: <span className="font-bold">{gameDetail.detail.score}</span>
          </p>
          <p className="text-2xl">
            completed in{" "}
            <span className="font-bold">{gameDetail.detail.hours}</span> hrs
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
