import "./App.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser, selectUsers } from "./store/usersSlice";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/config";
import Login from "./components/Login";
import CookieNotice from "./components/CookieNotice";
import Footer from "./components/Footer";
import MainNav from "./components/MainNav";
import GamesContainer from "./components/GamesContainer";
import Games from "./components/Games";
import FriendGames from "./components/FriendGames";
import Profile from "./components/Profile";
import { db } from "./firebase/config";
import { doc, getDoc } from "firebase/firestore";
import { homeGames } from "./services/homeGames";
import _ from "lodash";

const fetchUserData = async (userId) => {
  const userRef = doc(db, "users", userId);
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) {
    return userDoc.data();
  }
};

function App() {
  const dispatch = useDispatch();
  const user = useSelector(selectUsers);
  const [loginModal, setLoginModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user.uid).then((result) => {
          dispatch(
            setUser({
              id: user.uid,
              email: user.email,
              userName: result ? result.userName : "",
              fullName: result ? result.fullName : "",
              color: result ? result.color : "",
            })
          );
        });
      } else {
        dispatch(setUser(null));
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  function showLogin() {
    setLoginModal(true);
  }

  const closeModal = () => {
    setLoginModal(false);
  };
  const showProfile = (userEmail) => {
    setProfileModal(true);
  };
  const closeProfileModal = () => {
    setProfileModal(false);
  };

  const forgotCloseModal = () => {
    setForgotModal(false);
  };
  return (
    <>
      <div className="md:container md:mx-auto px-4 flex flex-col min-h-screen">
        <MainNav handleLogin={showLogin} handleProfile={showProfile} />
        <div className="flex flex-col w-fit mt-5 md:mt-15 ">
          <h1 className="  mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
              Start cleaning
            </span>{" "}
            your Backlog today!
          </h1>
          <h2 className="mb-4 text-lg font-extrabold leading-none text-gray-400 md:text-3xl dark:text-white leading-relaxed">
            Track your games and share with your friends
          </h2>
        </div>
        {!user.currentUser && (
          <div className="flex flex-col items-center md:flex-row justify-between mx-10  ">
            <Games
              className="mr-3"
              games={homeGames}
              loading={false}
              error={null}
              deleteGame={() => {}}
            />
          </div>
        )}
        {loginModal && !user.currentUser ? (
          <Login isModalOpen={loginModal} closeModal={closeModal} />
        ) : null}
        {user.currentUser && (
          <>
            <GamesContainer />
            <Profile
              isModalOpen={profileModal}
              closeModal={closeProfileModal}
            />
            <h2 className="text-3xl font-extrabold dark:text-white mt-7  mb-5 ">
              Friends
              <small className="ms-2 font-semibold text-gray-500 dark:text-gray-400">
                Backlog
              </small>
            </h2>
            <FriendGames />
          </>
        )}

        <Footer
          user={user.currentUser ? false : true}
          handleLogin={showLogin}
        />
      </div>
    </>
  );
}

export default App;
