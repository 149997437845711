import { useState, useEffect } from "react";
import {
  Badge,
  Dropdown,
  Button,
  Textarea,
  Label,
  RangeSlider,
} from "flowbite-react";
import GameStateButton from "../components/GameStateButton";
import Select from "react-select";
import { LuMenuSquare, LuTrash2, LuPen, LuClock3 } from "react-icons/lu";
import {
  GAME_STATES,
  GAME_STATUS,
  saveGameDetails,
} from "../services/gameService";
import { useSelector } from "react-redux";
import { selectUsers } from "../store/usersSlice";

function gameSize(duration) {
  if (duration <= 15) {
    return "Small game";
  }
  if (duration > 25) {
    return "Large game";
  }
  if (duration < 25) {
    return "Medium game";
  }
}
export default function Game({ game, onDelete }) {
  const [localGame, setLocalGame] = useState(game);
  const [originalGame, setOriginalGame] = useState(game);
  const user = useSelector(selectUsers);
  const [messages, setMessages] = useState({
    message: "",
    color: "",
    disabled: false,
  });
  useEffect(() => {
    // Guardar el juego original al montar el componente
    setOriginalGame(game);
  }, [game]);

  const formattedPlatforms = game.platforms.map((platform) => ({
    value: platform,
    label: platform,
  }));
  const handleEdit = () => {
    setLocalGame((prevGame) => ({
      ...prevGame,
      gameEditable: !prevGame.gameEditable,
    }));
  };
  const handleScoreChange = (event) => {
    setLocalGame((prevGame) => ({
      ...prevGame,
      score: event.target.value,
    }));
  };
  const handleHoursChange = (event) => {
    setLocalGame((prevGame) => ({
      ...prevGame,
      hours: event.target.value,
    }));
  };
  const handleReviewChange = (event) => {
    setLocalGame((prevGame) => ({
      ...prevGame,
      review: event.target.value,
    }));
  };
  const handleSaveGameDetails = (event) => {
    event.preventDefault();
    const data = {
      review: localGame.review ?? "",
      hours: localGame.hours ?? 0,
      score: localGame.score ?? 0,
      platform: localGame.platform ?? "",
    };
    saveGameDetails(game.id, user.currentUser.id, data)
      .then(() => {
        setMessages({
          message: "saved!",
          color: "success",
          disabled: true,
        });
      })
      .then(() => {
        setLocalGame((prevGame) => ({
          ...prevGame,
          gameEditable: false,
        }));
        setOriginalGame(localGame);
      });
  };
  const handlePlatformChange = (platform) => {
    setLocalGame((prevGame) => ({
      ...prevGame,
      platform: platform,
    }));
  };

  const changeStatus = (newState) => {
    setLocalGame((prevGame) => ({
      ...prevGame,
      status: newState,
    }));
  };

  const handleCancel = () => {
    setLocalGame(() => ({
      ...originalGame,
      gameEditable: false,
    })); // Restaurar el juego original
  };
  return (
    <li className="py-3 sm:py-4 " key={localGame.id}>
      <div className="flex  space-x-4 ">
        <div className="shrink-0">
          <img
            alt={localGame.name}
            height="90"
            src={localGame.imageUrl}
            width="90"
            className="rounded-m"
          />
        </div>
        <div className="w-full ">
          <div className="flex items-center  mb-2">
            <p className="truncate text-xl font-medium text-gray-900 dark:text-white max-w-52">
              {localGame.name}
            </p>
            {!localGame.gameEditable && (
              <div className="ml-auto">
                <Dropdown
                  className="min-w-32 "
                  label=""
                  dismissOnClick={true}
                  renderTrigger={() => (
                    <span>
                      <LuMenuSquare />
                    </span>
                  )}
                  placement="left"
                >
                  <Dropdown.Item onClick={handleEdit}>
                    <LuPen />
                    <span className=" flex justify-between ml-2">Edit</span>
                  </Dropdown.Item>
                  <Dropdown.Item className="relative" onClick={onDelete}>
                    <LuTrash2 className=" text-red-800" />
                    <span className=" text-red-800 flex justify-between ml-2">
                      Delete
                    </span>
                  </Dropdown.Item>
                </Dropdown>
              </div>
            )}
          </div>
          {localGame.gameEditable ? (
            <Textarea
              id="comment"
              placeholder="Leave a review..."
              required
              rows={4}
              onChange={handleReviewChange}
              value={localGame.review}
            />
          ) : (
            <span className="line-clamp-3 text-sm text-slate-700 mb-5">
              {localGame.review}
            </span>
          )}
          {localGame.gameEditable && (
            <>
              <Label>Select Platform</Label>
              <Select
                defaultValue={localGame.platform}
                options={formattedPlatforms}
                onChange={handlePlatformChange}
              />
            </>
          )}

          <div id="badges" className="flex flex-wrap gap-1 mt-2">
            {localGame.platform && !localGame.gameEditable && (
              <div className="flex-shrink-0">
                <Badge color="indigo">{localGame.platform.label}</Badge>
              </div>
            )}
            {!localGame.gameEditable && (
              <div className="flex-shrink-0">
                <Badge
                  color="gray"
                  className="w-full whitespace-nowrap overflow-hidden text-ellipsis"
                >
                  <span className="flex items-center  space-x-2">
                    <LuClock3 /> <span>{localGame.gameplayMain} hours</span>
                  </span>
                </Badge>
              </div>
            )}
            {!localGame.gameEditable && (
              <div className="flex-shrink-0">
                <Badge className="" color="gray">
                  {gameSize(localGame.gameplayMain)}
                </Badge>
              </div>
            )}

            <div className="flex-shrink-0">
              {localGame.gameEditable ? (
                <GameStateButton
                  game={localGame}
                  onUpdateStatus={changeStatus}
                />
              ) : (
                <Badge
                  className=""
                  color={GAME_STATUS[GAME_STATES[localGame.status]].color}
                >
                  {GAME_STATUS[GAME_STATES[localGame.status]].tag}
                </Badge>
              )}
            </div>
          </div>

          {localGame.gameEditable && (
            <>
              <div className="flex flex-row justify-between">
                <div className="mb-1 block w-1/2">
                  <Label htmlFor="sm-range" value=" score:" />
                </div>

                <RangeSlider
                  id="sm-range"
                  sizing="sm"
                  onChange={handleScoreChange}
                  value={localGame.score}
                />
              </div>
              <div className="flex flex-row justify-between">
                <div className="mb-1 block w-1/2">
                  <Label htmlFor="sm-range" value=" Hours played:" />
                </div>

                <RangeSlider
                  id="sm-range"
                  sizing="sm"
                  onChange={handleHoursChange}
                  value={localGame.hours}
                />
              </div>
            </>
          )}

          <div className="flex justify-between text-sm ">
            {localGame.hours !== 0 && (
              <span className="mt-2">
                My time:{" "}
                <span className="font-bold">{localGame.hours} hours</span>
              </span>
            )}
            {localGame.score !== 0 && (
              <span className="mt-2">
                Score: <span className="font-bold">{localGame.score}</span>
              </span>
            )}
          </div>
          {localGame.gameEditable && (
            <div className="flex">
              <Button
                size="sm"
                className="mt-3"
                onClick={(event) => handleSaveGameDetails(event)}
              >
                Save
              </Button>
              <Button
                size="sm"
                color="gray"
                className="mt-3 ml-4"
                onClick={(event) => handleCancel(event)}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white relative">
          <div className="flex flex-wrap  "></div>
        </div>
      </div>
    </li>
  );
}
