import { useState } from "react";
import logo from "../assets/logo-completo.png";
import Privacy from "../components/Privacy";
import Cookies from "../components/Cookies";
import { Button, Modal } from "flowbite-react";

export default function Footer({ user, handleLogin }) {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isCookiesOpen, setIsCookiesOpen] = useState(false);
  const openPrivacyModal = () => {
    setIsPrivacyOpen(true);
  };
  const openCookiesModal = () => {
    setIsCookiesOpen(true);
  };
  const closePrivacyModal = () => {
    setIsPrivacyOpen(false);
  };
  const closeCookiesModal = () => {
    setIsCookiesOpen(false);
  };
  return (
    <>
      <Privacy openModal={isPrivacyOpen} closeModal={closePrivacyModal} />
      <Cookies openModal={isCookiesOpen} closeModal={closeCookiesModal} />
      <footer className="bg-white dark:bg-gray-900 mt-16 ">
        <div className="container px-6 py-8 mx-auto">
          <div className="flex flex-col items-center text-center">
            <a href="#">
              <img
                className="w-auto h-7"
                src={logo}
                alt="game resolution logo"
              />
            </a>

            <p className="max-w-md mx-auto mt-4 text-gray-500 dark:text-gray-400">
              Start cleaning your Backlog today!
            </p>
            {user && (
              <div className="flex flex-col mt-4 sm:flex-row sm:items-center sm:justify-center">
                <Button
                  onClick={handleLogin}
                  className="w-full px-5 py-2 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform rounded-md sm:mx-2 sm:order-2 sm:w-auto hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                >
                  Get started
                </Button>
              </div>
            )}
          </div>

          <hr className="my-10 border-gray-200 dark:border-gray-700" />

          <div className="flex flex-col items-center sm:flex-row sm:justify-between">
            <p className="text-sm text-gray-500">
              © Copyright 2024. All Rights Reserved.
            </p>

            <div className="flex mt-3 -mx-2 sm:mt-0">
              <a
                onClick={openPrivacyModal}
                className="cursor-pointer mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"
                aria-label="Reddit"
              >
                Privacy
              </a>

              <a
                onClick={openCookiesModal}
                className="cursor-pointer mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"
                aria-label="Reddit"
              >
                Cookies
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
