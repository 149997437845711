import { useEffect, useState } from "react";
import { Tabs } from "flowbite-react";
import { LuUserPlus, LuUsers, LuSettings, LuSearch } from "react-icons/lu";
import { List, Button } from "flowbite-react";
import Filter from "../components/Filter";
import { getFriends, AcceptFriend } from "../services/userService";
import { selectUsers } from "../store/usersSlice";
import { useSelector } from "react-redux";
import AvatarWithLetter from "./AvatarLetter";
import _ from "lodash";

export default function Friends() {
  const user = useSelector(selectUsers);
  const [friendRequests, setFriendRequests] = useState([]);
  useEffect(() => {
    getFriends(user.currentUser.id).then((result) => {
      const groupedFriends = _.groupBy(result, "accepted");
      setFriendRequests(groupedFriends);
    });
  }, [friendRequests, user.currentUser.id]);

  const acceptFriend = (friendId) => {
    AcceptFriend(friendId, user.currentUser.id).then(() => {
      const updatedFriends = friendRequests.filter(
        (friend) => friend.id !== friendId
      );
      setFriendRequests(updatedFriends);
    });
  };
  const deleteFriend = () => {};

  return (
    <div className="overflow-x-auto w-72">
      <Tabs aria-label="Full width tabs" style="fullWidth">
        <Tabs.Item active icon={LuUsers}>
          <div className="mx-7">
            <h1 className="font-bold text-m my-5 uppercase">Friends</h1>
            <div className="relative w-full max-w-xs">
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Search..."
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <LuSearch
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
            <h2 className="font-bold text-xs my-5 uppercase">Friends</h2>
            <List
              unstyled
              className="max-w-md divide-y divide-gray-200 dark:divide-gray-700"
            >
              {friendRequests.true && friendRequests.true.length > 0 ? (
                friendRequests.true.map((friend) => {
                  return (
                    <List.Item className="pb-3 sm:pb-4" key={friend.id}>
                      <div className="flex items-center space-x-4 rtl:space-x-reverse">
                        <AvatarWithLetter
                          name={friend.userName}
                          bgColor={friend.color}
                        />
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                            {friend.userName}
                          </p>
                        </div>
                      </div>
                    </List.Item>
                  );
                })
              ) : (
                <li>No pending friend requests</li>
              )}
            </List>
          </div>
        </Tabs.Item>
        <Tabs.Item icon={LuUserPlus}>
          <div className="mx-7 min-h-96 relative">
            <div
              id="friendDetail"
              className="absolute right-70 top-25 z-50 "
            ></div>
            <h1 className="font-bold text-m my-5 uppercase">ADD FRIENDS</h1>
            <div>
              <Filter />
            </div>
            <h2 className="font-bold text-xs my-5 uppercase">
              Friend requests
            </h2>
            <List
              unstyled
              className="max-w-md divide-y divide-gray-200 dark:divide-gray-700"
            >
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                {friendRequests.false && friendRequests.false.length > 0 ? (
                  friendRequests.false.map((friend) => {
                    console.log(friend);
                    return (
                      <List.Item className="pb-3 sm:pb-4" key={friend.id}>
                        <div className="flex items-center space-x-4 rtl:space-x-reverse">
                          <AvatarWithLetter
                            key={friend.id}
                            name={friend.userName}
                            bgColor={friend.color}
                          />
                          <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                              {friend.userName}
                            </p>
                            <p className="truncate text-sm text-gray-500 dark:text-gray-400 flex">
                              <Button
                                size="xs"
                                color="blue"
                                className="mr-2"
                                onClick={() => acceptFriend(friend.id)}
                              >
                                Add
                              </Button>
                              <Button
                                size="xs"
                                color="gray"
                                onClick={() => deleteFriend(friend.id)}
                              >
                                Block
                              </Button>
                            </p>
                          </div>
                        </div>
                      </List.Item>
                    );
                  })
                ) : (
                  <li>No pending friend requests</li>
                )}
              </div>
            </List>
          </div>
        </Tabs.Item>
        <Tabs.Item icon={LuSettings}>
          <div className="mx-7"></div>
        </Tabs.Item>
      </Tabs>
    </div>
  );
}
