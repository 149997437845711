import { useState, useEffect } from "react";
import { Modal, TextInput, Label, Button } from "flowbite-react";
import { updateProfile, selectUsers } from "../store/usersSlice";
import { useSelector, useDispatch } from "react-redux";
import { db } from "../firebase/config";
import { useForm } from "react-hook-form";
import {
  collection,
  query,
  where,
  getDocs,
  runTransaction,
  doc,
  getDoc,
} from "firebase/firestore";

export default function Profile({ isModalOpen, closeModal }) {
  const dispatch = useDispatch();
  const user = useSelector(selectUsers);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userId = user.currentUser.id;
    if (userId) {
      const fetchUserData = async () => {
        const userRef = doc(db, "users", user.currentUser.id);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setValue("userName", userData.userName || "");
            setValue("fullName", userData.fullName || "");
            setValue("street", userData.address?.street || "");
            setValue("city", userData.address?.city || "");
            setValue("state", userData.address?.state || "");
            setValue("zipCode", userData.address?.zipCode || "");
            setValue("color", userData.color || "");
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchUserData();
    }
  }, [setValue, user.currentUser.id]);

  const onSubmit = async (data) => {
    const { userName, fullName, street, city, state, zipCode, color } = data;
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("userName", "==", userName));

    try {
      await runTransaction(db, async (transaction) => {
        const querySnapshot = await getDocs(q);

        if (
          !querySnapshot.empty &&
          querySnapshot.docs[0].id !== user.currentUser.id
        ) {
          throw new Error("The username is already in use.");
        }

        const userRef = doc(db, "users", user.currentUser.id); // Referencia al documento existente
        await transaction.set(
          userRef,
          {
            userName,
            fullName,
            color,
            address: {
              street,
              city,
              state,
              zipCode,
            },
          },
          { merge: true }
        ); // Merge permite actualizar campos sin eliminar otros existentes
      });
      clearErrors("userName");
      dispatch(
        updateProfile({
          userName,
          fullName,
          street,
          color,
          address: {
            street,
            city,
            state,
            zipCode,
          },
        })
      );
      closeModal();
    } catch (error) {
      if (error.message === "The username is already in use.") {
        setError("userName", {
          color: "failure",
          type: "manual",
          message: "The username is already in use.",
        });
      } else {
        console.error("Error updating user:", error);
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Modal show={isModalOpen} size="2xl" onClose={closeModal} popup>
      <Modal.Header />
      <Modal.Body>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-md mx-auto p-2 bg-white space-y-6"
        >
          <h3 className="text-xl font-medium text-gray-900 dark:text-white ">
            Profile
          </h3>
          <div className="mb-4">
            <Label htmlFor="nickname" value="User Name" />
            <TextInput
              {...register("userName", {
                required: "User name is required",
              })}
              id="nickname"
              type="text"
              placeholder="Enter your user name"
              required
              color={errors.userName && errors.userName.color}
              helperText={
                <>
                  {errors.userName && (
                    <span className="font-medium">
                      {errors.userName.message}
                    </span>
                  )}
                </>
              }
            />
          </div>
          <div className="mb-4">
            <Label htmlFor="email" value="Email" />
            <TextInput
              {...register("email")}
              id="email"
              type="email"
              placeholder="Enter your email"
              disabled
              required
              value={user.currentUser.email}
            />
          </div>
          <div className="mb-4">
            <Label htmlFor="name" value="Name" />
            <TextInput
              {...register("fullName")}
              id="name"
              type="text"
              placeholder="Enter your name"
            />
          </div>
          <h3 className="text-xl font-medium text-gray-900 dark:text-white space-y-6">
            Address
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <Label htmlFor="street" value="Street" />
              <TextInput
                {...register("street")}
                id="street"
                type="text"
                placeholder="Enter your street"
              />
            </div>
            <div>
              <Label htmlFor="city" value="City" />
              <TextInput
                {...register("city")}
                id="city"
                type="text"
                placeholder="Enter your city"
              />
            </div>
            <div>
              <Label htmlFor="state" value="State" />
              <TextInput
                {...register("state")}
                id="state"
                type="text"
                placeholder="Enter your state"
              />
            </div>
            <div>
              <Label htmlFor="zipCode" value="Zip Code" />
              <TextInput
                {...register("zipCode")}
                id="zipCode"
                type="text"
                placeholder="Enter your zip code"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <Button type="button" color="gray" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
