import { Button, Modal } from "flowbite-react";

export default function Privacy({ openModal, closeModal }) {
  return (
    <Modal show={openModal} onClose={() => closeModal(false)}>
      <Modal.Header>Privacy Policy</Modal.Header>
      <Modal.Body>
        <div className="container mx-auto p-4">
          <p className="mb-4">
            <strong>Introduction:</strong> We value your privacy and are
            committed to protecting your personal information. This Privacy
            Policy outlines how we collect, use, and protect your information.
          </p>

          <h2 className="text-2xl font-semibold mb-2">
            Information We Collect
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Personal Information:</strong> We do not collect any
              personal information unless you provide it to us directly.
            </li>
            <li>
              <strong>Usage Data:</strong> We may collect non-personal
              information about how you interact with our website, such as your
              IP address, browser type, and pages visited.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-2">
            How We Use Your Information
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>To Improve Our Services:</strong> We use the information
              to enhance and improve your experience on our website.
            </li>
            <li>
              <strong>Communication:</strong> If you contact us, we may use your
              contact information to respond to your inquiries.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-2">
            Sharing Your Information
          </h2>
          <p className="mb-4">
            We do not share your personal information with third parties unless
            required by law.
          </p>

          <h2 className="text-2xl font-semibold mb-2">Your Rights</h2>
          <p className="mb-4">
            You have the right to access, correct, or delete your personal
            information. Please contact us if you wish to exercise these rights.
          </p>

          <h2 className="text-2xl font-semibold mb-2">Security</h2>
          <p className="mb-4">
            We implement security measures to protect your personal information.
            However, no method of transmission over the internet or electronic
            storage is 100% secure.
          </p>

          <h2 className="text-2xl font-semibold mb-2">
            Changes to This Policy
          </h2>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new policy on this page.
          </p>

          <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
          <p className="mb-4">
            If you have any questions about this Privacy Policy, please contact
            us at info@gameresolution.app.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModal(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
