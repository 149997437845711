import { GAME_STATES } from "../services/gameService";
export default function GameStateTag({ status }) {
  const states = GAME_STATES;
  const stateIcons = {
    Playing: {
      tag: "Playing",
      color: "text-blue-900",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24"
          height="24"
          className="text-blue-900 w-4 h-4 inline-block mr-1"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
          />
          <path
            d="M12 2a10 10 0 0 1 0 20V12H2A10 10 0 0 1 12 2z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    Pending: {
      color: "text-yellow-900",
      tag: "Pending",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24"
          height="24"
          className="text-yellow-900 w-4 h-4 inline-block mr-1"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
          />
          <line
            x1="8"
            y1="12"
            x2="16"
            y2="12"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
      ),
    },
    Completed: {
      color: "text-green-900",
      tag: "Completed",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24"
          height="24"
          className="text-green-900 w-4 h-4 inline-block mr-1"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
          />
          <path
            fillRule="evenodd"
            d="M10 15.27l-3.55-3.55a.75.75 0 0 1 1.06-1.06L10 13.14l6.5-6.5a.75.75 0 0 1 1.06 1.06L10 15.27z"
            fill="currentColor"
          ></path>
        </svg>
      ),
    },
  };

  return (
    <span className={stateIcons[states[status]].color}>
      {stateIcons[states[status]].tag}
      <span className="ml-2">{stateIcons[states[status]].svg}</span>
    </span>
  );
}
