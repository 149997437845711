import { doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase/config";

export const GAME_STATES = ["Pending", "Playing", "Completed"];

export const GAME_STATUS = {
  Pending: { color: "gray", tag: "Pending" },
  Playing: { color: "blue", tag: "Playing" },
  Completed: { color: "green", tag: "Completed" },
};

export const updateGameStatus = async (userId, gameId, state) => {
  const docRef = await doc(db, "users", userId + "/games/" + gameId);
  const status = state;
  await updateDoc(docRef, {
    status: status,
  });
};

export const fetchGameDetails = async (userId, gameId) => {
  const docRef = await doc(db, "users", userId + "/games/" + gameId);
  const gameDoc = await getDoc(docRef);
  if (gameDoc.exists()) {
    return {
      ...gameDoc.data(),
    };
  }
};

export const saveGameDetails = async (gameId, userId, data) => {
  const docRef = await doc(db, "users", userId + "/games/" + gameId);
  await updateDoc(docRef, data);
};

export const deleteGame = async (userId, gameId) => {
  await deleteDoc(doc(db, "users", userId + "/games/" + gameId));
};
