import { Card } from "flowbite-react";

import Game from "../components/Game";

export default function Games({ games, loading, error, deleteGame }) {
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {games.map((year) => {
        return (
          <Card className="mt-6 max-w-prose" key={year.year}>
            <div className="mb-4 flex items-center justify-between">
              <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                Backlog of the {year.year}
              </h5>
            </div>
            <div className="flex h-full flex-col justify-start ">
              <div className="flow-root ">
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                  {year.games.map((game) => {
                    return (
                      <Game
                        key={game.id}
                        game={game}
                        onDelete={() => deleteGame(game.id, year.year)}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
          </Card>
        );
      })}
    </>
  );
}
