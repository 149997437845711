import { Card, Button } from "flowbite-react";
import { createPortal } from "react-dom";
import AvatarLetter from "../components/AvatarLetter";

export default function FriendDetail({ user, closeModal }) {
  return createPortal(
    <Card className="max-w-sm justify-center">
      <div className="text-center p-8">
        <AvatarLetter name={user.userName} bgColor={user.color} />
        <h3 className="font-bold">{user.userName}</h3>
        <Button size="xs" className="w-full" onClick={() => closeModal(user)}>
          Add Friend
        </Button>
      </div>
    </Card>,
    document.getElementById("friendDetail")
  );
}
