import { Select } from "flowbite-react";
export default function Search({ year, results, saveItem, searching, term }) {
  return (
    <div className="flex justify-center relative">
      <input
        className="w-1/2 md:w-1/4 rounded-md bg-gray-200 text-gray-700 leading-tight focus:outline-none py-2 px-2"
        id="search"
        type="text"
        placeholder="look for your next game for:"
        value={term}
        onChange={(event) => searching(event)}
      />
      <Select
        className="ml-4"
        id="countries"
        required
        onChange={(event) => year(event)}
      >
        <option value="2024">2024</option>
        <option value="2025">2025</option>
      </Select>
      {results.length > 0 && (
        <div className="absolute top-full mt-2 w-1/2 md:w-1/2 bg-slate-100 border border-gray-200 rounded-md max-h-80 overflow-y-auto z-10">
          {results.map((result) => (
            <div
              className="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2"
              key={result.id}
              onClick={() => saveItem(result)}
            >
              <span
                className={
                  (result.id ? "bg-green-400" : "bg-gray-400") +
                  " h-2 w-2 m-2 rounded-full"
                }
              ></span>
              <div className="flex-grow font-medium px-2">{result.name}</div>
              <div className="text-sm font-normal text-gray-500 tracking-wide">
                {result.gameplayMain}hr
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
