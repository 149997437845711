export const homeGames = [
  {
    year: 2024,
    games: [
      {
        id: "109239",
        completed: false,
        year: 2024,
        platform: {
          value: "Nintendo Switch",
          label: "Nintendo Switch",
        },
        score: "0",
        hours: "0",
        status: 2,
        review: "",
        platforms: [
          "Nintendo Switch",
          "PC",
          "PlayStation 4",
          "PlayStation 5",
          "Xbox One",
          "Xbox Series X/S",
        ],
        playableOn: [
          "Nintendo Switch",
          "PC",
          "PlayStation 4",
          "PlayStation 5",
          "Xbox One",
          "Xbox Series X/S",
        ],
        gameplayMainExtra: 5,
        imageUrl: "https://howlongtobeat.com/games/109239_Cocoon.jpg",
        gameplayMain: 5,
        description: "",
        gameplayCompletionist: 5,
        name: "Cocoon",
        similarity: 0.83,
      },
      {
        id: "5953",
        review: "",
        status: 0,
        year: 2024,
        hours: "31",
        completed: false,
        platform: {
          label: "Nintendo Switch",
          value: "Nintendo Switch",
        },
        score: "8",
        playableOn: ["Nintendo GameCube", "Nintendo Switch", "Wii"],
        platforms: ["Nintendo GameCube", "Nintendo Switch", "Wii"],
        description: "",
        similarity: 0.54,
        gameplayCompletionist: 17,
        imageUrl: "https://howlongtobeat.com/games/250px-MetroidPrimebox.jpg",
        gameplayMainExtra: 16,
        name: "Metroid Prime",
        gameplayMain: 14,
      },
      {
        id: "72854",
        score: "9.1",
        platform: {
          value: "PC",
          label: "PC",
        },
        status: 2,
        completed: false,
        hours: "6",
        year: 2024,
        review: "",
        gameplayMain: 7,
        description: "",
        similarity: 0.19,
        platforms: ["PC", "Xbox Series X/S"],
        gameplayMainExtra: 8,
        gameplayCompletionist: 9,
        imageUrl:
          "https://howlongtobeat.com/games/72854_Senuas_Saga_Hellblade_II.jpg",
        name: "Senua's Saga: Hellblade II",
        playableOn: ["PC", "Xbox Series X/S"],
      },
    ],
  },
  {
    year: 2025,
    games: [
      {
        id: "82089",
        platform: {
          value: "PC",
          label: "PC",
        },
        hours: 1,
        year: 2024,
        score: 1,
        status: 0,
        review: "",
        completed: false,
        description: "",
        imageUrl: "https://howlongtobeat.com/games/82089_Black_Myth_Wukong.jpg",
        gameplayMainExtra: 0,
        similarity: 0.44,
        gameplayCompletionist: 0,
        gameplayMain: 0,
        playableOn: ["PC", "PlayStation 5", "Xbox Series X/S"],
        name: "Black Myth: Wukong",
        platforms: ["PC", "PlayStation 5", "Xbox Series X/S"],
      },
      {
        id: "85713",
        year: 2024,
        score: "8.9",
        status: 0,
        review: "",
        platform: {
          label: "PC",
          value: "PC",
        },
        completed: false,
        hours: "116",
        imageUrl:
          "https://howlongtobeat.com/games/85713_Nioh_2_Complete_Edition.jpg",
        gameplayCompletionist: 132,
        name: "Nioh 2: Complete Edition",
        platforms: ["PC", "PlayStation 4", "PlayStation 5"],
        similarity: 0.25,
        gameplayMain: 42,
        playableOn: ["PC", "PlayStation 4", "PlayStation 5"],
        gameplayMainExtra: 87,
        description: "",
      },
      {
        id: "93683",
        platform: {
          label: "Xbox Series X/S",
          value: "Xbox Series X/S",
        },
        status: 0,
        score: "71",
        review: "",
        year: 2024,
        hours: "5",
        completed: false,
        gameplayMainExtra: 5,
        description: "",
        gameplayMain: 5,
        name: "Bramble: The Mountain King",
        similarity: 0.27,
        gameplayCompletionist: 7,
        platforms: [
          "Nintendo Switch",
          "PC",
          "PlayStation 4",
          "PlayStation 5",
          "Xbox One",
          "Xbox Series X/S",
        ],
        playableOn: [
          "Nintendo Switch",
          "PC",
          "PlayStation 4",
          "PlayStation 5",
          "Xbox One",
          "Xbox Series X/S",
        ],
        imageUrl:
          "https://howlongtobeat.com/games/93683_Bramble_The_Mountain_King.jpg",
      },
    ],
  },
];
