import { useState, useEffect } from "react";
import { getFriends, getGamesByYears } from "../services/userService";
import { useSelector } from "react-redux";
import { selectUsers } from "../store/usersSlice";
import GameStatusTag from "../components/GameStateTag";
import FriendGameDetail from "../components/FriendGameDetail";
import { LuEye } from "react-icons/lu";

export default function FriendGames() {
  const user = useSelector(selectUsers);
  const [friendGames, setFriendGames] = useState([]);
  const [friendGameModal, setFriendGameModal] = useState(false);
  const [gameDetail, setGameDetail] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener los amigos del usuario actual
        const friends = await getFriends(user.currentUser.id);
        const friendDetails = friends.map((friend) => ({
          id: friend.id,
          ...friend,
        }));

        // Crear un array de promesas para obtener datos del perfil de cada amigo
        const friendProfiles = await Promise.all(
          friendDetails.map(async (friend) => {
            const userData = await getGamesByYears(friend.id);
            return {
              id: friend.id,
              user: { ...friend },
              games: { ...userData },
            };
          })
        );

        setFriendGames(friendProfiles);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [user.currentUser.id]);

  const showFriendGameModal = (game) => {
    setFriendGameModal(true);
    setGameDetail(game);
  };
  const closeFriendModal = () => {
    setFriendGameModal(false);
  };

  return (
    <>
      {friendGameModal && (
        <FriendGameDetail
          openModal={friendGameModal}
          setOpenModal={closeFriendModal}
          gameDetail={gameDetail}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {friendGames && friendGames.length > 0 ? (
          friendGames.map((friend) => {
            return (
              <div
                className="rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 overflow-hidden"
                key={friend.user.id}
              >
                <div className="p-4">
                  <h3 className="text-lg font-bold text-gray-800">
                    {friend.user.userName}
                  </h3>
                  <ul className="mt-4">
                    {Object.keys(friend.games).map((year) => {
                      return (
                        <div key={year}>
                          <h2 className="font-bold">{year}</h2>
                          {friend.games[year].map((game) => {
                            return (
                              <li
                                className="flex justify-between items-center mb-2"
                                key={game.detail.id}
                              >
                                <span className="text-sm text-gray-600">
                                  {game.title.name}
                                </span>
                                <div className="flex items-end gap-2">
                                  <GameStatusTag status={game.detail.status} />
                                  {game.detail.review && game.detail.score ? (
                                    <LuEye
                                      size="1.2em"
                                      className="cursor-pointer"
                                      onClick={() => showFriendGameModal(game)}
                                    />
                                  ) : (
                                    <span style={{ width: "1.2em" }}></span>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })
        ) : (
          <p>no items</p>
        )}
      </div>
    </>
  );
}
